<template>
  <div class="total row">
    <div class="col-5 col-md-8 text-start">
      <slot></slot>
    </div>
    <div class="col-2">Total</div>
    <div class="col-5 col-md-2 text-end">
      <Dinheiro :valor="total" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dinheiro from "@/components/shared/formatadores/Dinheiro.vue";

export default {
  name: "ValorTotalPedido",
  components: {
    Dinheiro,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["total"]),
  },
};
</script>

<style scoped>
.total {
  color: var(--cor-tema);
  font-weight: 600;
  font-size: 1.2em;
}
</style>